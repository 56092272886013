import * as React from "react";

import Frame from "../components/frame";
import { HeadMeta } from "../components/head-meta";

import {Timeline} from '../components/timeline';
import InternalHero from "../components/internal-hero";
import {StaticImage} from 'gatsby-plugin-image';
import SimpleFooter from "../components/basic-footer";

export const Head = () => {
	return <HeadMeta title="Our History" />;
};
const TheEcclesStory = () => {
	return (
		<Frame contained="false">
			<InternalHero
				eyebrow={"Our History"}
				backdrop={
					<StaticImage
						src={`../images/heros/history-header.jpg`}
						alt="background image"
						aria-hidden="true"
						placeholder="blurred"
						layout="fullWidth"
						loading="eager"
					/>
				}>
				<h1>Building on the Eccles Legacy</h1>
				<p>
					In 2013, The Cynosure Group was founded to support the private investments
					and portfolio management for the Eccles Family. Learn about the rich history
					of one of the Western United States’ most influential families.
				</p>
			</InternalHero>
			<Timeline />
			<SimpleFooter
				text="Our clients benefit from the same experience, access, and insights we provide to our founding family"
			/>
		</Frame>
	);
};

export default TheEcclesStory;
