import { StaticImage } from "gatsby-plugin-image";
import React, { useEffect, useState } from "react";
import * as styles from "../styles/timeline.module.scss";

export const Timeline = ({ title = "", description = "", children }) => {
    const [currentlyActive, setCurrentlyActive] = useState(-1);

    const dotLine = (flip = false) => {
        return (
            <>
                { flip ? null : <div className={styles.dot}></div> }
                <div className={styles.lineWrap}>
                    <div className={styles.lineFill}></div>
                </div>
                { flip ? <div className={styles.dot}></div> : null }
            </>
        )
    };

    const scrollHander = (e) => {
        const centerPosition = window.innerHeight/5;
        const els = document.querySelectorAll('[data-state]');
        let switchedAt = -1;
        els.forEach((el,i)=>{
            if( el.getBoundingClientRect().y <= centerPosition ){
                el.dataset.state = 'activated';
            }else if(el.dataset.state !== 'activated'){
                if( switchedAt < 0){
                    switchedAt = i;
                }
            }
        })
        if( switchedAt >= 0 && currentlyActive !== switchedAt ){
            setCurrentlyActive(switchedAt);
            els[switchedAt].dataset.state = 'active';
        }
    }

    const slideshowHandler = () => {
        const currentSection =  document.querySelector('[data-state="active"]');
        if( currentSection === null ){
            return
        }
        const allFrames = currentSection.querySelectorAll('[data-gatsby-image-wrapper]');
        let currentFrame = parseInt(currentSection.dataset?.currentFrame) || 0;
        if( currentFrame+1 >= allFrames.length){
            currentFrame = 0;
        }else{
            currentFrame++;
        }
        currentSection.dataset.currentFrame = currentFrame;
        allFrames.forEach((frame , i)=>{
            if( frame.classList.contains('active')) {
                frame.classList.add('previous')
            }else{
                frame.classList.remove('previous')
            }
        });
        allFrames.forEach((frame , i)=>{
            if( currentFrame === i){
                allFrames[i].classList.add('active');
            }else{
                allFrames[i].classList.remove('active');
            }
        })
    }

    const slideShower = ( index = 0 ) => {
        const currentSection =  document.querySelectorAll('[data-state]')[index];

        const allFrames = currentSection.querySelectorAll('[data-gatsby-image-wrapper]');
        let currentFrame = parseInt(currentSection.dataset?.currentFrame) || 0;
        if( currentFrame+1 >= allFrames.length){
            currentFrame = 0;
        }else{
            currentFrame++;
        }
        currentSection.dataset.currentFrame = currentFrame;

        allFrames.forEach((frame , i)=>{
            if( frame.classList.contains('active')) {
                frame.classList.add('previous')
            }else{
                frame.classList.remove('previous')
            }
        });
        allFrames.forEach((frame , i)=>{
            if( currentFrame === i){
                allFrames[i].classList.add('active');
            }else{
                allFrames[i].classList.remove('active');
            }
        })
    }

    useEffect(()=>{
        window.addEventListener('scroll',scrollHander)
        // setInterval(slideshowHandler, 7000)
        setTimeout(()=>{setInterval(()=>{slideShower(0);}, 8000);}, 0);
        setTimeout(()=>{setInterval(()=>{slideShower(1);}, 8000);}, 1000);
        setTimeout(()=>{setInterval(()=>{slideShower(2);}, 8000);}, 2000);
        setTimeout(()=>{setInterval(()=>{slideShower(3);}, 8000);}, 3000);
        setTimeout(()=>{setInterval(()=>{slideShower(4);}, 8000);}, 4000);
        setTimeout(()=>{setInterval(()=>{slideShower(5);}, 8000);}, 5000);
        setTimeout(()=>{setInterval(()=>{slideShower(6);}, 8000);}, 6000);
        scrollHander();
        return ()=>{
            window.removeEventListener('scroll',scrollHander)
        }
    },[])

	return (
        <div className={`${styles.container} ${styles.timelineWrapper}`}>

            <div className={styles.timelineRow} data-state="pending">
                <div className={styles.line}>
                    {dotLine()}
                </div>
                <div className={styles.header}>
                    <h2>19th Century</h2>
                </div>
                <div className={styles.image}>
                    <div className={`${styles.imageWrap} ${styles.imageClipped}`}>
                        <StaticImage quality={85} width={530} height={530} src="../images/timeline/19th-A.jpg" className="active" alt="19th century" />
                        <StaticImage quality={85} width={530} height={530} src="../images/timeline/19th-B.jpg" alt="19th century" />
                    </div>
                </div>
                <div className={styles.content}>
                    <h5>1863</h5>
                    <p>
                    At the age of 14, David Eccles sails with his family from Scotland to America
                    aboard the ship Cynosure. He never forgets his impoverished beginnings, providing
                    generous aid to families, businesses, and communities in his later years
                    </p>
                    <h5>1873</h5>
                    <p>
                    David Eccles forms Eccles, Gibson and Van Noy, the first of 54 companies he would create
                    and invest in the course of his life, becoming one of the most successful industrialists of the
                    west, and Utah's first multimillionaire. Over the next 23 years, much of his profits are reinvested
                    into other Utah enterprises such as banks, insurance companies, railroads, factories, and real estate.
                    </p>
                </div>
            </div>

            <div className={styles.timelineRow} data-state="pending">
                <div className={styles.line}>
                    {dotLine()}
                </div>
                <div className={styles.header}>
                    <h2>Early 20th Century</h2>
                </div>
                <div className={styles.image}>
                    <div className={`${styles.imageWrap} ${styles.imageClippedLeft}`}>
                        <StaticImage quality={85} width={530} height={530} src="../images/timeline/early-20th-A.jpg" className="active" alt="Early 20th Century" />
                        <StaticImage quality={85} width={530} height={530} src="../images/timeline/early-20th-B.jpg" alt="Early 20th Century" />
                    </div>
                </div>
                <div className={styles.content}>
                    <h5>1912</h5>
                    <p>
                    David Eccles dies in 1912 in Salt Lake City, Utah. At the time of his
                    death at age 63, he was president of 17 industrial corporations and 7
                    banks, and a director of  24 other banks and industries. On the day of
                    his funeral, government offices and private businesses throughout the
                    West halt operations to honor his memory.
                    </p>
                    <h5>1916</h5>
                    <p>
                    Through the Eccles Investment Company, a family holding company, David's
                    sons, Marriner and George Eccles, acquire control of many of their
                    father's enterprises on behalf of the family.
                    </p>
                    <h5>1928</h5>
                    <p>
                    E.G. Bennett and Marriner and George Eccles combine extensive business
                    entities and the six banks their father left them to launch the First
                    Security Corporation, a holding company to manage their family's numerous
                    banks and savings and loan institutions.
                    </p>
                </div>
            </div>

            <div className={styles.timelineRow} data-state="pending">
                <div className={styles.line}>
                    {dotLine()}
                </div>
                <div className={styles.header}>
                    <h2>20th Century</h2>
                </div>
                <div className={styles.image}>
                    <div className={`${styles.imageWrap} ${styles.imageClipped }`}>
                        <StaticImage quality={85} width={530} height={530} src="../images/timeline/20th-A.jpg" className="active" alt="20th century" />
                        <StaticImage quality={85} width={530} height={530} src="../images/timeline/20th-B.jpg" alt="20th Century" />
                    </div>
                </div>
                <div className={styles.content}>
                    <p>
                    Throughout the 20th Century, the Eccles family's holdings include some of the
                    largest lumber, construction, mining, beet sugar processing, and banking
                    companies in the U.S. Among the family's landmark activities, Utah Construction
                    led the six companies that built the Hoover Dam and much of the infrastructure
                    of the western United States, and the First Security Bank was the first and
                    oldest multi-state bank holding company in the country.
                    </p>
                    <p>
                    The family's legacy is marked by iconic buildings like the First Security Bank
                    Building in the heart of Utah's capital city, Salt Lake City, and the Union
                    Pacific Railroad Depot that was part of the first Transcontinental Railroad
                    spanning the nation.
                    </p>
                </div>
            </div>

            <div className={styles.timelineRow} data-state="pending">
                <div className={styles.line}>
                    {dotLine()}
                </div>
                <div className={styles.header}>
                    <h2>Federal Reserve</h2>
                </div>
                <div className={styles.image}>
                    <div className={`${styles.imageWrap} ${styles.imageClippedLeft }`}>
                        <StaticImage quality={85} width={530} height={530} src="../images/timeline/federal-reserve-A.jpg" className="active"  alt="Federal Reserve"/>
                    </div>
                </div>
                <div className={styles.content}>
                    <h5>1934</h5>
                    <p>
                    Marriner S. Eccles was appointed Chairman of the Federal Reserve Board on
                    November 15, 1934. Before entering public service, Eccles gained national
                    attention for successfully steering the First Security Corporation through
                    the banking crises of early 1930's, a time when 11,000 banks in the US failed.
                    </p>
                    <p>
                    In 1933, Congress invited him to give his analysis of the Great Depression.
                    In his testimony, he proposed a five-point program to fix the economy that
                    formed the basis of the New Deal.
                    </p>
                    <p>
                    Eccles made such a lasting impact on the Federal Reserve System that the
                    Federal Reserve Building in Washington, DC, was renamed in his honor.
                    </p>
                </div>
            </div>

            <div className={styles.timelineRow} data-state="pending">
                <div className={styles.line}>
                    {dotLine()}
                </div>
                <div className={styles.header}>
                    <h2>Late 20th Century</h2>
                </div>
                <div className={styles.image}>
                    <div className={`${styles.imageWrap} ${styles.imageClipped }`}>
                        <StaticImage quality={85} width={530} height={530} src="../images/timeline/late-20th-A.jpg" className="active"  alt="Late 20th century" />
                        <StaticImage quality={85} width={530} height={530} src="../images/timeline/late-20th-B.jpg" alt="Late 20th century" />
                        <StaticImage quality={85} width={530} height={530} src="../images/timeline/late-20th-C.jpg" alt="Late 20th century" />
                    </div>
                </div>
                <div className={styles.content}>
                    <p>
                    At the end of the 20th century, the family sells most of its long-held private
                    investments to large public companies in some of the landmark merger and
                    acquisition transactions of the era including the sale of Utah Construction to
                    General Electric in what was then the largest merger transaction in US history.
                    </p>
                    <p>
                    The proceeds of these sales funded the growth of the family's many philanthropic
                    activities. Descendants of David Eccles have formed over a dozen foundations
                    devoted to supporting civic life in Utah and the West. Today, gifts of the
                    Eccles family account for almost 20% of all the private philanthropy in the
                    state of Utah.
                    </p>
                </div>
            </div>

            <div className={styles.timelineRow} data-state="pending">
                <div className={styles.line}>
                    {dotLine()}
                </div>
                <div className={styles.header}>
                    <h2>21st Century</h2>
                </div>
                <div className={styles.image}>
                    <div className={`${styles.imageWrap} ${styles.imageClippedLeft }`}>
                        <StaticImage quality={85} width={530} height={530} src="../images/timeline/21st-A.jpg" className="active" alt="21th century" />
                        <StaticImage quality={85} width={530} height={530} src="../images/timeline/21st-B.jpg" alt="21th century" />
                        <StaticImage quality={85} width={530} height={530} src="../images/timeline/21st-C.jpg" alt="21th century" />
                    </div>
                </div>
                <div className={styles.content}>
                    <h5>2013</h5>
                    <p>
                    Continuing the Eccles family legacy established over a century ago, The Cynosure
                    Group is formed in 2013 as an investment firm commissioned to manage the
                    family investment portfolio, as well as those of other long-term investors.
                    </p>
                    <p>
                    Cynosure's investment management plays a key role building long-term investment
                    portfolios crafted to help carry on the philanthropic efforts of the Eccles family.
                    </p>
                    <h5>2023</h5>
                    <p>
                    In the spring and summer of 2023, Cynosure added two new divisions, Cynosure Wealth
                    Advisors and Cynosure Strategies, while reorganizing the broader firm into four
                    distinct business lines, including Cynosure Partners and Cynosure Capital Management.
                    </p>
                </div>
            </div>

            <div className={`${styles.timelineRow} ${styles.lastRealTimelineRow}`} data-state="pending">
                <div className={styles.line}>
                    {dotLine()}
                </div>
                <div className={styles.header}>
                    <h2>Today</h2>
                </div>
                <div className={styles.image}>
                    <div className={`${styles.imageWrap} ${styles.imageClipped }`}>
                        <StaticImage quality={85} width={530} height={530} src="../images/timeline/today-A.jpg" className="active" alt="cynosure today" />
                    </div>
                </div>
                <div className={styles.content}>
                    <p>
                    Today, The Cynosure Group continues the Eccles family investment legacy, providing
                    long-term, client-focused comprehensive investment advice across asset classes to a
                    variety of like-minded individuals, family offices, and institutions.
                    </p>
                </div>
            </div>

            <div className={`${styles.timelineRow} ${styles.lastTimelineRow}`} data-state="pending">
                <div className={styles.image}>
                </div>
                <div className={styles.line}>
                    {dotLine(true)}
                </div>
                <div className={styles.content}>
                </div>
            </div>

        </div>
	);
};
