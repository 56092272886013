import { hasNames } from "../utils";
import { StaticImage } from "gatsby-plugin-image";
import * as React from "react";
import { forwardRef } from "react";

import * as styles from "../styles/contact-us-footer.module.scss";

const SimpleFooter = forwardRef((props, ref) => {
	const text = props.text || "Your company. Your vision. Let us help you get there.";
	const image = props.image || <StaticImage quality={85} className={styles.image} src="../images/angled-theater.png" alt="" aria-hidden="true" layout="fullWidth" />;

	return (
		<>
			<div className={styles.wrap}>
				<div className={styles.container} ref={ref}>
					<div className={styles.left}>
						<h2 className={styles.message}>{text}</h2>
					</div>
					<div className={hasNames(styles.right, styles.imageWrapper)}>
						{image}
					</div>
				</div>
			</div>
		</>
	);
});

export default SimpleFooter;
